import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { CertificateItem, DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
  selector: 'app-certificate-view',
  templateUrl: './certificate-view.component.html',
  styleUrls: ['./certificate-view.component.css']
})
export class CertificateViewComponent implements OnInit {

  @Input() device: string | null = null

  public gridApi!: GridApi;
  public isLoading = false;
  
  public columnDefs: ColDef[] = [
    { headerName: "Certificate ID", field: 'id', resizable: true, width: 550 }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // Data that gets displayed in the grid
  public rowData: CertificateItem[] = []

  constructor(
    private deviceInfo: DeviceInfoService) { }

  ngOnInit() {
    if(this.device) {
      this.isLoading=true;
      this.updateLoadingOverlay();
      this.deviceInfo.Certificates(this.device).subscribe((data: CertificateItem[]) => {
        this.rowData = data
        this.isLoading=false;
        this.updateLoadingOverlay();
      })
    }
  }
  
  onGridReady(params: GridReadyEvent){
    this.gridApi= params.api;
    this.updateLoadingOverlay();
  }
  private updateLoadingOverlay(): void {
    if (this.gridApi) {
      if (this.isLoading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }
}