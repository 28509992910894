import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CellValueChangedEvent, ColDef, DomLayoutType, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CrmtNumericEditor } from 'src/app/cell-editors/crmt-numeric-editor.component';
import { PeriodTimerParameters, PeriodTimerParametersItem } from 'src/app/utils/period-timers-parameters';
import { GenericHeaderEditorComponent } from '../generic-header-editor/generic-header-editor.component';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';
import { EditStateService } from 'src/app/services/edit-state.service';


@Component({
  selector: 'app-period-timer-parameters-editor',
  templateUrl: './period-timer-parameters-editor.component.html',
  styleUrls: ['./period-timer-parameters-editor.component.css']
})
export class PeriodTimerParametersEditorComponent implements OnInit {

  @Input() crmtFile?: PeriodTimerParameters
  @ViewChild(GenericHeaderEditorComponent) headerEditor?: GenericHeaderEditorComponent

  public rowData!: PeriodTimerParametersItem[]
  public enableEditing = false
  public disableEditing = true

  private edited = false
  private gridApi!: GridApi;
  public domLayout: DomLayoutType = "autoHeight";
  public pagination = true;
  public paginationPageSize = 10;
  public gridOptions: GridOptions = {
    stopEditingWhenCellsLoseFocus: true,
  };
  public columnDefs: ColDef[] = [
    { 
      headerName: "Duration", 
      field: 'duration', 
      resizable: false,
      //Keeping it for EPCOL-621
      // editable: () => this.enableEditing,
      // cellEditor: CrmtNumericEditor, 
      // cellEditorParams: {
      //   min: 0,
      //   max: 24 * 60 * 60
      // }
    }
  ];

  constructor(
    private readonly permissions: PermissionsService, private editStateService: EditStateService) {}

  ngOnInit() { 

    this.permissions.getUserLevel().subscribe(level => {

      this.enableEditing = level >= UserLevel.STANDARD
      this.disableEditing = !this.enableEditing
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.crmtFile = changes["crmtFile"].currentValue

    if(this.crmtFile) {
    
      this.rowData = this.crmtFile.entries
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public editRow() {
    const selection = this.gridApi.getSelectedNodes()
    const index  = selection[0].rowIndex
    this.gridApi!.setFocusedCell(index as number, "duration");
    this.gridApi!.startEditingCell({
      rowIndex: index as number,
      colKey: "duration",
    });
  }

  public onCellValueChanged(e: CellValueChangedEvent<any, any>) {
    this.edited = true
    this.editStateService.setEditedState(true);
  }


  public getData() {

    const headerEdited = this.headerEditor?.edited

    return {
      done: this.edited || headerEdited,
      fileType: 'period-timer-parameters',
      fileData: this.crmtFile
    }
  }

  public exitEditMode() {
    this.edited = false
  }
}


