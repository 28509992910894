import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IxSelect } from '@siemens/ix-angular';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';

export interface CommandOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-crmt-command-selector',
  templateUrl: './crmt-command-selector.component.html',
  styleUrls: ['./crmt-command-selector.component.css']
})
export class CrmtCommandSelectorComponent implements OnInit {

  @ViewChild('versionInput') versionInput?: ElementRef;
  @ViewChild('stockTypeInput') stockTypeInput?: IxSelect;
  @ViewChild('stockNumberInput') stockNumberInput?: ElementRef;
  @ViewChild('languageCodeInput') languageCodeInput?: ElementRef;

  @Output() getSelectedCommandType = new EventEmitter<string>();
  public options: CommandOptions[];

  public selectedType?: string

  constructor(private nextBtnStateService: NextBtnStateService) { 
    this.options = [
      { label: 'Activate software', value: 'activate-software' },
      { label: 'Cab radio details', value: 'write-details' },
      { label: 'Reboot radio', value: 'reboot-radio' },
      { label: 'Clear fan usage log', value: 'clear-fan-usage-log' },
      { label: 'Clear fault log', value: 'clear-fault-log' },
    ];
  }

  ngOnInit(): void {
  }

  public typeChanged(event: any): void {
    this.nextBtnStateService.setDisableNextBtn(false)
    this.selectedType = event.detail[0]
    this.getSelectedCommandType.emit(this.selectedType)
  }

  public getData() {

    let response: any

    if(this.selectedType == 'activate-software') {

      response = this.validateActivateSoftware()

    }
    else if(this.selectedType == 'write-details') {

      response = this.validateWriteDetails()
    }
    else {

      const done: boolean = Boolean(this.selectedType)

      response = {
        done,
        type: this.selectedType
      }
    }

    return response
  }

  private validateActivateSoftware(): any {

    let done = true
    const swVersion = parseInt(this.versionInput?.nativeElement.value)

    this.versionInput?.nativeElement.classList.remove('range-error')

    if(Number.isNaN(swVersion) ||
      swVersion < 0 || 
      swVersion > 2 ** 16 - 1) {

      this.versionInput?.nativeElement.classList.add('range-error')
      done = false
    }

    return {
      done,
      type: this.selectedType,
      swVersion
    }
  }

  private validateWriteDetails(): any {

    let done = true
    const stockType = this.stockTypeInput?.value
    const stockNumber: string = this.stockNumberInput?.nativeElement.value
    this.stockNumberInput?.nativeElement.classList.remove('range-error')

    if(stockNumber == undefined ||
      stockNumber.length > 9) {
        
      this.stockNumberInput?.nativeElement.classList.add('range-error')
      done = false
    }

    const languageCode = parseInt(this.languageCodeInput?.nativeElement.value)
    this.languageCodeInput?.nativeElement.classList.remove('range-error')

    if(Number.isNaN(languageCode) ||
      languageCode < 0 || 
      languageCode > 255) {

      this.languageCodeInput?.nativeElement.classList.add('range-error')
      done = false
    }

    return {
      done,
      type: this.selectedType,
      stockType,
      stockNumber,
      languageCode
    }
  }
}

