import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService, ToastType, UploadFileState } from '@siemens/ix-angular';
import { Subscription } from 'rxjs';
import { CloudFileSelectorComponent } from 'src/app/components/cloud-file-selector/cloud-file-selector.component';
import { CrmtDateSelectorComponent } from 'src/app/components/crmt-date-selector/crmt-date-selector.component';
import { CrmtDeviceSelectorComponent } from 'src/app/components/crmt-device-selector/crmt-device-selector.component';
import { ReviewKeyPair } from 'src/app/components/crmt-job-review/crmt-job-review.component';
import { ApiService, DeployJobRequest } from 'src/app/services/api.service';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-deploy-file',
  templateUrl: './deploy-file.component.html',
  styleUrls: ['./deploy-file.component.css']
})
export class DeployFileComponent implements OnInit {

  @ViewChild(CloudFileSelectorComponent) fileSelector?: CloudFileSelectorComponent
  public fileSelectorData?: any
  @ViewChild(CrmtDeviceSelectorComponent) deviceSelector?: CrmtDeviceSelectorComponent
  public deviceSelectorData?: any
  @ViewChild(CrmtDateSelectorComponent) dateSelector?: CrmtDateSelectorComponent
  public dateSelectorData?: any
  @ViewChild('customToast', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  public reviewData!: ReviewKeyPair[]
  public state = UploadFileState.SELECT_FILE;
  public files: File[] = []
  public jobId: string = ""

  public selectedTab = 0

  public status: string[] = [
    'open',
    'open',
    'open',
    'open'
  ]

  public selectedFile?: string
  public submitted = false
  public disableStandard = true
  public isError = false
  public disableNextBtn! : boolean
  private subscription!: Subscription
  public deviceType='';

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly toastService: ToastService,
    private router: Router,
    public readonly nextBtnStateService: NextBtnStateService
  ) {}

  ngOnInit(): void {
    this.permissions.getUserLevel().subscribe(level => {
      this.disableStandard = level < UserLevel.STANDARD
    })
    this.subscription = this.nextBtnStateService.getDisableNextBtn()
    .subscribe(value => {
      this.disableNextBtn = value
    });
  }

  public deviceHandler(devices: string[]) {

    if(devices.length > 0) {
      this.status[1] = 'done'
    }
    else {
      this.status[1] = 'open'
    }
   }

   public previous() {
    if(this.selectedTab > 0) {
      this.selectedTab--
    }
  }

  public navigation(selected : number) {
    if(this.selectedTab > selected) {
      this.selectedTab = selected
    } else if(this.selectedTab == 0 || this.selectedTab == 1 || this.selectedTab == 2) {
      this.next()
    }
  }

  public getDeviceType(e: any){
    this.deviceType = e
  }

  // TODO: This will be refactored in due course
  public next() {
    if(this.selectedTab == 0) {
      this.fileSelectorData = this.fileSelector?.getData()

      if(this.fileSelectorData?.done) {
        this.status[0] = 'done'
        this.incrementTab()
      }
      else {
        this.status[0] = 'error'
      }
    }
    else if(this.selectedTab == 1) {
      this.deviceSelectorData = this.deviceSelector?.getData()

      if(this.deviceSelectorData?.done) {
        this.status[1] = 'done'
        this.incrementTab()
      }
      else {
        this.status[1] = 'error'
      }
    }
    else if(this.selectedTab == 2) {
      this.dateSelectorData = this.dateSelector?.getData()

      if(this.dateSelectorData?.done) {
        this.status[2] = 'done'

        this.reviewData = [
          { key: "Type", value: this.fileSelectorData?.type},
          { key: "File", value: this.fileSelectorData?.files},
          this.deviceSelectorData.groupMode ? {
            key: "Groups", value: this.updateGroupNames(this.deviceSelectorData.groups).join(', ')
          } : { 
            key: "Devices", value: this.getDeviceStockNumber(this.deviceSelectorData?.devices).join(', ')
          },
          { key: "Schedule", value: this.dateSelectorData?.now ? 'Deploy now' : `Deploy later (${this.dateSelectorData.startTime})`},
        ];

        this.incrementTab()
      }
    }
    else if(this.selectedTab == 3) {
      this.submit()
      this.incrementTab()
    }
  }

  private getDeviceStockNumber(selection: string[]) {
    let deviceStockNumber: string[] = []
    selection.forEach((element: any) => {
      deviceStockNumber.push(element.engineNumber)
    });
    return deviceStockNumber
  }

  private updateGroupNames(strings: string[]): string[] {
    return strings.map(groupName => {
     var indexOfSpecialCharacter = groupName.indexOf(':')
     if (indexOfSpecialCharacter > 0) {
        return groupName.substring(indexOfSpecialCharacter + 1);
      } else {
        return groupName;
      }
   });
  }

  public deployAnotherJob() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['deploy-file'])
    });
  }

  private incrementTab(): void {

    if(this.selectedTab < 4) {
      this.selectedTab++
    }
  }

  private submit() {
    this.status[3] = 'done'
    const file = this.fileSelectorData.files

    // Convert the GUI file type to an API file type
    const fileType = this.fileSelectorData.type
    const hardware = this.fileSelectorData.hardware
    const groupMode = this.deviceSelectorData.groupMode
    const job: DeployJobRequest = {
      fileType,
      groups: groupMode ? this.deviceSelectorData?.groups : null,
      devices: groupMode ? null : this.getDeviceName(this.deviceSelectorData?.devices),
      scheduleLater: this.dateSelectorData?.now == false,
      startTime: this.dateSelectorData?.startTime
    }

    job.filename = `uploads/${fileType}/${hardware}/${file}`

    this._apiService.deploy(job).subscribe({
        next: (data) => {
          this.jobId = data.message.jobId
          this.showToastMessage()
          this.submitted = true
        },
        error: (e: any) => {
          console.error('Error occurred:', e);
          if(e.status == 0){// This is the 0 = unknown Error
            this.errorToast("Unable to process your request. Please try again later or contact the administrator.")
          }else {
            this.errorToast(`Error occurred while deploying file. Please contact administrator.`)
          }
          this.isError = true
          this.submitted = false   
          this.previous()         
        }
    })
  }

  private getDeviceName(selection: string[]) {
    let deviceName: string[] = []
    selection.forEach((element: any) => {
      deviceName.push(element.name)
    });
    return deviceName
  }

  async showToastMessage(message = 'Deployment job submitted', type: ToastType = 'success') {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message,
      type,
      autoCloseDelay: 10000
    });
  }


  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error',
      autoCloseDelay: 10000
    });
  }

  ngOnDestroy(): void {
    this.nextBtnStateService.setDisableNextBtn(true)
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
