import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, of, retry, switchMap, throwError, timer } from 'rxjs';
import { CrmtFile, ICrmtFile } from '../utils/crmt-file';
import { environment } from './../../environments/environment';
import { CrmtFileFactory } from '../utils/crmt-file-parser';
import { FileListItem } from '../components/cloud-file-selector/cloud-file-selector.component';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';

export type CommandJobRequest = {

  command: string;
  swVersion: number;
  stockType: boolean;
  stockNumber: string;
  languageCode: number;
  devices: string[] | null;
  groups: string[] | null;
  scheduleLater: boolean;
  startTime?: string;
}

export type DeployJobRequest = {

  fileType: string;
  filename?: string;
  iniFilename?: string;
  zipFilename?: string;
  groups?: string[] | null;
  devices?: string[] | null;
  scheduleLater: boolean;
  startTime?: string;
}

type CancelJobBody = {
  jobId: string,
  comment?: string,
}

type DeleteFileBody = {
  fileName: string,
  comment?: string,
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  private thingArn = ''
  private accountInfo: any

  constructor(private http: HttpClient) { }

  // Implements CRMT-LTE-SSRS-5568
  public fleetListDevices(nextToken: string | undefined, paginationPageSize: number): Observable<any[]> {

    const url = this.apiUrl + 'fleet/list-devices'
    
    const body = {
      nextToken,
      paginationPageSize
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs.pipe(
      switchMap((response: any) => 
        of(response.message.things)
      )
    )
  }

  public registerDevice(
    thingType: string,
    serialNumber: string) {

    const url = this.apiUrl + 'provision'

    const body = {
      thingType,
      serialNumber
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public getAuditLog(
    startTime: number,
    endTime: number
  ): Observable<any[]> {

    const url = this.apiUrl + 'audit'

    let queryParams = new HttpParams();
    queryParams = queryParams.append("startTime", startTime);
    queryParams = queryParams.append("endTime", endTime);

    const dataObs = this.http.get<any[]>(url, { params: queryParams });

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public getJobList(nextToken: string, paginationPageSize: number): Observable<any[]> {

    const url = this.apiUrl + 'job-list'

    const body = {
      nextToken,
      paginationPageSize
    }
    const dataObj = this.http.post<any>(url,body);
    return dataObj
  }

  public getFileList(
    fileType: string,
    hardwareType: string): Observable<any[]> {

    const url = this.apiUrl + 'file-list'
    let queryParams = new HttpParams();
    queryParams = queryParams.append("fileType", fileType);
    queryParams = queryParams.append("hardwareType", hardwareType);
    const dataObs = this.http.get<any>(url, { params: queryParams });

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public getSoftwarePackageFileList(folderName: string): Observable<any[]> {

    const url = this.apiUrl + 'software-package-file-list'
    let queryParams = new HttpParams();
    queryParams = queryParams.append("folderPath", folderName);
    const dataObs = this.http.get<any>(url, { params: queryParams });

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public listDeviceTypes(): Observable<string[]> {

    const url = this.apiUrl + 'device-types'
    const dataObs = this.http.get<any>(url);

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public getAccountInfo(): Observable<any> {

    if (this.accountInfo) {

      // Use cached data
      return of(this.accountInfo)
    }
    else {

      const url = this.apiUrl + 'account-info'
      const dataObs = this.http.get<any>(url);

      return dataObs.pipe(
        switchMap((response: any) => {
          this.accountInfo = response.message
          return of(this.accountInfo)
        }),
        retry(10)
      )
    }
  }

  public groupDeviceTree(): Observable<string[]> {

    const url = this.apiUrl + 'fleet/group-device-tree'
    const dataObs = this.http.get<any>(url);

    return dataObs
  }

  public fleetDeviceTreeInGroup(nextToken: string | undefined, 
    paginationPageSize: number, groupName: string, 
    filePath: string[]): Observable<string[]> {

    const url = this.apiUrl + 'fleet/list-devices-in-group'

    const body = {
      paginationPageSize,
      nextToken,
      groupName,
      filePath
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public fleetListGroups(): Observable<string[]> {

    const url = this.apiUrl + 'fleet/list-groups'
    const dataObs = this.http.get<any>(url)

    return dataObs.pipe(
      switchMap((response: any) => of(response.message))
    )
  }

  public fleetAddGroup(thingGroupName: string, parentGroupName: string, thingGroupDescription: string) {

    const url = this.apiUrl + 'fleet/add-thing-group'

    const body = {
      thingGroupName,
      parentGroupName,
      thingGroupDescription
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public fleetRemoveGroup(thingGroupName: string) {

    const url = this.apiUrl + 'fleet/remove-thing-group'
    const body = { thingGroupName }
    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public fleetMoveThingToGroup(
    oldGroupName: string,
    newGroupName: string,
    thingName: string) {

    const url = this.apiUrl + 'fleet/move-thing-to-group'

    const body = {
      oldGroupName,
      newGroupName,
      thingName
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public fleetAddThingToGroup(
    newGroupName: string,
    thingName: string) {

    const url = this.apiUrl + 'fleet/add-thing-to-group'

    const body = {
      newGroupName,
      thingName
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public fleetRemoveThingFromGroup(
    group: string,
    thingName: string) {

    const url = this.apiUrl + 'fleet/remove-thing-from-group'

    const body = {
      group,
      thingName
    }

    const dataObs = this.http.post<any>(url, body);

    return dataObs
  }

  public getJobStatus(jobId: string) {

    const url = this.apiUrl + 'job/job-status'
    let queryParams = new HttpParams();
    queryParams = queryParams.append("jobId", jobId);
    return this.http.get<any>(url, { params: queryParams })
  
  }

  public getJobStatusDetails(
    jobId: string,
    devices: string[]) {
    const url = this.apiUrl + 'job/job-status-details'
    const body = {
      jobId,
      devices
    }
    const dataObs = this.http.post<any>(url, body);
    return dataObs
  }

  public sendCommand(commandJob: CommandJobRequest) {

    const url = this.apiUrl + 'command'
    return this.http.post<any>(url, commandJob).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error('An error occurred:', err);
        return throwError(() => err);
      })
    );
  }

  public deploy(deployJob: DeployJobRequest) {

    const url = this.apiUrl + 'deploy'
    return this.http.post<any>(url, deployJob).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error('An error occurred:', err);
        return throwError(() => err);
      })
    );
  }

  public cancelJobService(cancelJobBody: CancelJobBody[]): Observable<any> {
    console.debug("cancelJobBody : ", cancelJobBody)
    const url = this.apiUrl + 'cancel-job'
    console.debug("url : ", url)
    return this.http.put<any>(url, cancelJobBody).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error('An error occurred:', err.error);
        return throwError(() => err);
      })
    );

  }

  public retrieve(
    fileType: string,
    device: string): Observable<any> {

    const url = this.apiUrl + 'retrieve'

    const body = {
      fileType,
      tempFile: true,
      device
    }

    return this.http.post<any>(url, body)
  }

  public getFile(fileType: string, device: string): Observable<CrmtFile> {

    const retrieveObs = this.retrieve(fileType, device)

    return retrieveObs.pipe(
      switchMap(result => {

        if (result.message.lambdaResponse.succeeded == false) {
          throw new Error("Retrieve operation failed")
        }
        const filename = result.message.filename

        return this.download(filename, fileType)
      })
    )
  }

  // Fetch a file from AWS
  public download(filename: string, fileType: string): Observable<CrmtFile> {

    // Request a presigned url
    const urlObs = this.getDownloadUrl(filename)

    const options: any = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'binary/octet-stream'
      }
    }

    return urlObs.pipe(
      switchMap(presignedUrl => this.http.get(presignedUrl, options)),
      switchMap(data => of(CrmtFileFactory.parse(fileType, data))),
      catchError((err: HttpErrorResponse) => {
        console.error('An error occurred:', err.error);
        return throwError(() => err);
      })
    )
  }

  // Fetch a file from AWS
  public async downloadSoftwarePackage(folderPath: string, data: FileListItem[]) {
    var folderName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
    var zip = new JSZip();

    // Download all files concurrently
    const downloadPromises = data.map(async (item) => {
      const fileToDownload = `${folderPath}/${item.Key}`;

      // Get download URL
      const url = await lastValueFrom(this.getDownloadUrl(fileToDownload));

      // Download file as blob
      const blob = await lastValueFrom(this.downloadSoftwarePackageFile(url));

      // Add the blob to the zip file
      const fileName = fileToDownload.substring(fileToDownload.lastIndexOf('/') + 1);
      zip.file(fileName, blob);
    });

    // Wait for all files to be downloaded and added to the zip
    await Promise.all(downloadPromises);

    // Generate zip and save
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        FileSaver.saveAs(content, `${folderName}.zip`)
      })
      .catch((err) => {
        console.error("An error occurred while generating zip:", err);
        return throwError(() => err);
      });
  }

  private downloadSoftwarePackageFile(signedUrl: string) {
    return this.http.get(signedUrl, { responseType: 'blob' });
  }

  public deleteFileFromAWS(deleteFileBody: DeleteFileBody[]): Observable<any> {
    const url = this.apiUrl + 'delete-file'
    console.debug("deleted-file lambda url : ", url)

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: deleteFileBody
    };

    return this.http.delete<any>(url, options).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error('An error occurred:', err.error);
        return throwError(() => err);
      })
    );
  }

  // Get presigned url for uploading
  public getUploadUrl(filename: string): Observable<string> {

    const url = this.apiUrl + 'upload'
    return this.getPresignedUrl(url, filename)
  }

  // Get a presigned url for downloading
  public getDownloadUrl(filename: string): Observable<string> {

    const url = this.apiUrl + 'download'
    return this.getPresignedUrl(url, filename)
  }

  // Call the lambda the generates urls
  private getPresignedUrl(url: string, filename: string): Observable<string> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("filename", filename);

    return this.http.get<any>(url, { params: queryParams }).pipe(
      switchMap(data => of(data.message.url))
    )
  }

  public putFile(filename: string, fileType: string, devices: string[], file: ICrmtFile): Observable<any> {

    // Encode the file file - this probably needs a virtual method
    const rawData = file.toRawData()

    // Upload the file
    const uploadObs = this.uploadFile(filename, rawData)

    // Deploy the file
    return uploadObs.pipe(
      switchMap(() => {

        const job: DeployJobRequest = {
          fileType,
          filename: `uploads/${filename}`,
          devices,
          scheduleLater: false
        }

        return this.deploy(job)
      })
    )
  }

  // Upload a file or data to AWS
  public uploadFile(
    filename: string,
    data: File | Blob | ArrayBuffer | string): Observable<any> {

    const urlObs = this.getUploadUrl(filename);

    return urlObs.pipe(
      switchMap(url => this.http.put<any>(url, data))
    )
  }


  public save(fileType: string, crmtFile: ICrmtFile) {

    let link = document.createElement('a');
    let fileName = this.getFileName(crmtFile);
    link.download =  fileName == "" ? fileType : fileName;
    const rawData = crmtFile.toRawData()

    let blob = new Blob([rawData], { type: 'binary/octet-stream' });

    link.href = URL.createObjectURL(blob);

    link.click();

    URL.revokeObjectURL(link.href);
  }

  public test() {

    const url = this.apiUrl + 'test'
    return this.http.get<any>(url);
  }

  public setThingArn(arn: string) {
    this.thingArn = arn
  }

  public getThingArn() {
    return this.thingArn
  }

  private getFileName(crmtFile: ICrmtFile){
    let fileName = "";
    if(crmtFile.header != undefined){
      let header  = crmtFile.header;
      fileName = `${header.name}_${header.majorVersion}.${header.minorVersion}`;
    }  
    return fileName;
  }
}
