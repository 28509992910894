import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef,
  } from '@angular/core';
  import { ICellEditorAngularComp } from 'ag-grid-angular';
  import { ICellEditorParams } from 'ag-grid-community';

  // backspace starts the editor on Windows
  const KEY_BACKSPACE = 'Backspace';
  const KEY_F2 = 'F2';
  const KEY_ENTER = 'Enter';
  const KEY_TAB = 'Tab';
  
  @Component({
    selector: 'numeric-cell',
    template: `<input
      #input
      (keydown)="onKeyDown($event)"
      [(ngModel)]="value"
      class="ag-input-field-input ag-text-field-input"
    />`,
  })
  export class PhoneNumberEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value!: string;
    public highlightAllOnFocus = true;
    private cancelBeforeStart = false;
    private smsCharSet = '+1234567890'
    private phoneCharSet = '*#+1234567890'
    private digitsCharSet = '1234567890'
    private validCharSet = ''

    @ViewChild('input', { read: ViewContainerRef })
    public input!: ViewContainerRef;
  
    agInit(params: ICellEditorParams): void {
      this.params = params;
      this.setInitialState(this.params);
  
      // only start edit if key pressed is a number, not a letter
      const eventKey = params.eventKey;
      this.validCharSet = this.getValidCharSet(this.params.phoneNumberType)
      this.cancelBeforeStart = !!(
        eventKey &&
        eventKey.length === 1 &&
        this.validCharSet.indexOf(eventKey) < 0
      );
    }
  
    setInitialState(params: ICellEditorParams) {
      let startValue;
      let highlightAllOnFocus = params.cellStartedEdit;
      const eventKey = params.eventKey;
  
      if (eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (eventKey && eventKey.length === 1) {
        // if a letter was pressed, we start with the letter
        startValue = eventKey;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = params.value;
        if (params.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }
  
      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    }
  
    getValue(): string | null {
      const value = this.value;
      return value
    }
  
    isCancelBeforeStart(): boolean {
      return this.cancelBeforeStart;
    }
  
    isCancelAfterEnd(): boolean {
      const value = this.getValue();
      const minLength = this.params.minLength
      const maxLength = this.params.maxLength
      const length = value ? value.length : 0
      const valid = minLength <= length && length <= maxLength
      return !valid
    }
  
    onKeyDown(event: any): void {
      if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        navigator.clipboard.readText().then((text) => {
          const numberValue = Number(text);
          if (!isNaN(numberValue)) {
            this.value = numberValue.toString();
          }
        }).catch((error) => {
          console.error('Error reading clipboard:', error);
        });
      }
      if (this.isLeftOrRight(event) || this.isBackspace(event)) {
        event.stopPropagation();
        return;
      }
  
      if (!this.finishedEditingPressed(event) && !this.isNumericKey(event)) {
        if (event.preventDefault) event.preventDefault();
      }
    }
  
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
      window.setTimeout(() => {
        if (this.highlightAllOnFocus) {
          this.input.element.nativeElement.focus();
          this.input.element.nativeElement.select();
        }
      });
    }

    focusIn() {
      this.input.element.nativeElement.focus();
      this.input.element.nativeElement.select();
    }
  
    private getValidCharSet(type: string): string {

      let charSet: string

      switch(type) {
        case 'phone': charSet = this.phoneCharSet; break;
        case 'sms': charSet =this.smsCharSet; break;
        case 'digits-only': charSet = this.digitsCharSet; break;

        default: charSet = this.phoneCharSet; break;
      }

      return charSet
    }

    private isCharValid(charStr: string): boolean {

      return this.validCharSet.indexOf(charStr) >= 0
    }
  
    private isNumericKey(event: any): boolean {
      const charStr = event.key;
      return this.isCharValid(charStr);
    }
  
    private isBackspace(event: any) {
      return event.key === KEY_BACKSPACE;
    }
  
    private isLeftOrRight(event: any) {
      return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    }
  
    private finishedEditingPressed(event: any) {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    }
  }
  