import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { DeviceInfoService } from 'src/app/services/device-info.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  @Input() device: string | null = null

  public gridApi!: GridApi;
  public isLoading = false;

  @Output() deviceInventoryInfo = new EventEmitter<string[]>();

  public columnDefs: ColDef[] = [
    { headerName: "Name", field: 'name', resizable: true },
    { headerName: "Value", field: 'value', resizable: true }
  ];

  public rowData: any[] = []

  constructor(
    private deviceInfo: DeviceInfoService) { }

    
    onGridReady(params: GridReadyEvent){
      this.gridApi= params.api;
      this.updateLoadingOverlay();
    }

    private updateLoadingOverlay(): void {
      if (this.gridApi) {
        if (this.isLoading) {
          this.gridApi.showLoadingOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      }
    }

  ngOnInit() {
    if(this.device) {
      this.isLoading=true;
      this.updateLoadingOverlay();
      this.deviceInfo.Inventory(this.device).subscribe((data: any) => {

        const inventory = data.shadow?.inventory
        const softwareVersions = data.shadow?.softwareVersions
        const attributes = data.attributes
        this.deviceInventoryInfo.emit(data)
        let stockType: string
        switch(inventory?.stockType) {
          case 0: stockType = "Engine"; break;
          case 1: stockType = "Coach"; break;
          default: stockType = "-"; break;
        }

        this.rowData = [{
          name: "Vehicle Number",
          value: inventory?.engineNumber.trim() || "-",
        },
        {
          name: "Connected",
          value: attributes?.connected ? attributes?.connected.charAt(0).toUpperCase() + attributes?.connected.slice(1) : "False",
        },
        {
          name: "S/W Version",
          value: inventory?.ver || "-", 
        },
        {
          name: "Current S/W Build",
          value: softwareVersions?.currentVersion || "-",
        },
        {
          name: "Available S/W Build",
          value: softwareVersions?.alternateVersion || "-", 
        },
        {
          name: "Serial number",
          value: inventory?.serialNumber || "-",
        },
        {
          name: "Trx Type",
          value: inventory?.mtType || "-",
        },
        {
          name: "Trx Model",
          value: inventory?.mtModel || "-",
        },
        {
          name: "Trx S/W Version",
          value: inventory?.mtAppver || "-",
        },
        {
          name: "Trx IMEI",
          value: inventory?.mtIMEI || "-",
        },
        {
          name: "Vehicle Type",
          value: stockType,
        },
        {
          name: "Language Version",
          value: softwareVersions?.currentLanguageVersion || "-",
        },
        {
          name: "Device Model",
          value: data.deviceType
        },
        {
          name:"GSM-R MSISDN",
          value: data.gsmrMSISDN || "-"
        },
        {
          name:"LTE SIM ICCID",
          value: data.lteMSISDN || "-"
        },
        {
          name:"Device Active",
          value: data.hmiActivated || "-"
        },
        {
          name: "Time Connected",
          value: attributes?.timeConnected ? moment.unix(attributes?.timeConnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",
        },
        {
          name: "Time Disconnected",
          value: attributes?.timeDisconnected ? moment.unix(attributes?.timeDisconnected/1000).local().format('YYYY-MM-DD HH:mm:ss') : "-",
        }]

        this.isLoading = false;
        this.updateLoadingOverlay();
      })
    }
  }
}
