<div class="wrapper">
    <div class="container">
        <div class="icon">
            <ix-icon name="circle-filled" color="{{iconColor}}" size="24"></ix-icon>
        </div>
        <div class="header">
            <ix-content-header width="100%"
            hasBackButton="false"
            headerTitle="Device Configuration for {{deviceStockNumber}}"
            headerSubtitle="Details of a single device">
            </ix-content-header>
        </div>
    </div>
    
    
    <div class="example">
        <ix-tabs [selected]="selectedTab">
          <ix-tab-item (click)="changeTab(0)">Inventory</ix-tab-item>
          <ix-tab-item (click)="changeTab(1)">Vehicle Number History</ix-tab-item>
          <ix-tab-item [disabled]="!isDeviceConnected" (click)="changeTab(2)">Config Files</ix-tab-item>
          <ix-tab-item 
            [disabled]="disableStandard || !isDeviceConnected"
            (click)="changeTab(3)">
            Command
          </ix-tab-item>
          <ix-tab-item
            [disabled]="disableStandard || !isDeviceConnected"
            (click)="changeTab(4)">
            Orientation
          </ix-tab-item>
          <ix-tab-item (click)="changeTab(5)">Certificates</ix-tab-item>
        </ix-tabs>
        <div *ngIf="selectedTab === 0">
            <app-inventory
                [device]="deviceId" (deviceInventoryInfo)="handleInventoryInfo($event)">
            </app-inventory>
        </div>
        <div *ngIf="selectedTab === 1">
            <app-stock-history
                [device]="deviceId">
            </app-stock-history>
        </div>
        <div *ngIf="selectedTab === 2">
            <app-crmt-file-editor 
                [device]="deviceId"
                (fileTypeChanged)="onFileTypeChange($event)"
                (getSelectedFileType)="getFileType($event)"
            >
            </app-crmt-file-editor>
        </div>
        <div *ngIf="selectedTab === 3">
            <app-crmt-command-selector (getSelectedCommandType)="getCommandType($event)"></app-crmt-command-selector>
        </div>
        <div *ngIf="selectedTab === 4">
            <app-orientation-tool
                [device]="deviceId"
                (orientation)="orientationHandler($event)">
            </app-orientation-tool>
        </div>
        <div *ngIf="selectedTab === 5">
            <app-certificate-view 
                [device]="deviceId">
            </app-certificate-view>
        </div>
      </div>
    <div class="button-panel" *ngIf="disableUpdateDeviceButton">
        <ix-button [disabled]="disableStandard || disableUpdate" class="m-1" [hidden]="!showUpdateDevice" [outline]="false"
            variant="Primary" (click)="updateDeviceHandler()">
            Update Device
        </ix-button>
    </div>
    <div *ngIf="selectedTab === 2 && loadingUploadFile" class="spinner-container">
      <div class="spinner">
          <p>Loading job details ...</p>
      </div>
      <div class="spinner">
          <ix-spinner size="large"></ix-spinner>
      </div>
    </div>
    <div style="margin-top: 1rem;" *ngIf="(selectedTab === 3 || selectedTab === 2) && jobId && !fileTypeChanged && !loadingUploadFile && !selectedCommandChanged">
        <ix-content-header
            class="mid-page"
            width="100%"
            hasBackButton="false"
            headerTitle="Job Details"
            [headerSubtitle]="'Live update of job' + jobId">
        </ix-content-header>

        <app-crmt-job-status
            [jobId]="jobId">
        </app-crmt-job-status>
    </div>
</div>  

<ng-template #customModal let-modal>
    <ix-modal>
      <ix-modal-header> Exit without saving? </ix-modal-header>
      <ix-modal-content>The modified values will be lost. Do you still want to proceed?</ix-modal-content>
      <ix-modal-footer>
        <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
          Cancel
        </ix-button>
        <ix-button class="close-modal" (click)="modal.close('okay')">
          OK
        </ix-button>
      </ix-modal-footer>
    </ix-modal>
  </ng-template>
