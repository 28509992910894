import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';

type LocalConfig =  {

  type: string
  fileCount: number;
  fileTypes: string[];
}

export type LocalFileSelection  = {

  stateValid: boolean;
  deviceType?: string;
  fileType?: string;
  files: File[];
}

@Component({
  selector: 'app-local-file-selector',
  templateUrl: './local-file-selector.component.html',
  styleUrls: ['./local-file-selector.component.css']
})
export class LocalFileSelectorComponent implements OnInit {

  @Output() localFileSelection = new EventEmitter<LocalFileSelection>();

  public selectedFileType: string = ""
  public selectedFiles: File[] = []
  public selectedFileText = ""
  public validExtensions = ""
  public expectedFileConfig: LocalConfig = this.getConfig("")
  public selectedDeviceType?: string
  public selectionState: LocalFileSelection = {
    stateValid: false,
    deviceType: undefined,
    fileType: undefined,
    files: []
  }
  public enableEditOption: boolean = false
  public fileTypeSelected = false;
  
  constructor(private readonly toastService: ToastService) {}

  ngOnInit(): void {}

  public filesChanged(event: any): void {

    // Sort the files by name 
    const files: File[] = event.detail
    files.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    // Extract the filenames
    const filenames = files.map(f => f.name)

    if(this.validateFileSelection(filenames, this.expectedFileConfig)) {

      // Update our display
      this.selectedFileText = filenames.join(", ")

      // Save the changes
      this.selectionState.files = files

      // Report the change
      this.output()
    }
  }

  public fileTypeChanged(event: any): void {
    this.fileTypeSelected = true;
    // No actual change
    if(this.selectedFileType == event) return

    // Update our display
    this.selectedFileText = ""

    // Update the info about the expected files
    this.expectedFileConfig = this.getConfig(event)
    this.validExtensions = this.expectedFileConfig.fileTypes.join(", ")

    // Reset the selected file info
    this.selectionState.fileType = event
    this.selectionState.files = []

    // Report the changes
    this.output()
  }

  public deviceTypeChanged(event: any) {
    this.enableEditOption = true
    // No actual change
    if(this.selectedDeviceType == event) return

    // Store the changes
    this.selectionState.deviceType = event

    // Report the changes
    this.output()
  }

  private output() {

    // Check the overall validity
    this.selectionState.stateValid =       
      Boolean(this.selectionState.deviceType) &&
      Boolean(this.selectionState.fileType) &&
      Boolean(this.selectionState.files.length)

    // Punt the value up
    this.localFileSelection.emit(this.selectionState)
  }

  public getData() {

    const done: boolean = 
      Boolean(this.selectedFiles?.length) && 
      Boolean(this.selectedFileType) &&
      Boolean(this.selectedDeviceType)

    return {
      done,
      type: this.selectedFileType,
      hardware: this.selectedDeviceType,
      files: this.selectedFiles,

    }
  }

  private getConfig(type: string) {

    let config: LocalConfig = {
      type,
      fileCount: 1,
      fileTypes: [""]
    }

    switch(type) {

      case "standard-phonebook":
        config.fileTypes  = [".spb", ".db"]; 
        break;
      
      case "extended-phonebook": 
        config.fileTypes = [".epb", ".db"]; 
        break;

      case "sms-database": 
        config.fileTypes = [".sms", ".db"]; 
        break;

      case "uus-database": 
        config.fileTypes = [".uus", ".db"];
        break;

      case "software-package": 
        config.fileCount = 2;
        config.fileTypes = [".ini", ".tar.gz"]; 
        break;
    }

    return config;
  }

  private validateFileExtension(filename: string, extensions: string[]): boolean {

    let valid = false

    extensions.forEach(ext => {

      valid ||= filename.endsWith(ext)
    })

    return valid
  }

  private validateMultipleFileExtensions(filenames: string[], extensions: string[]): boolean {

    let valid = true

    filenames.forEach(f => {

      valid &&= this.validateFileExtension(f, extensions)

    })

    return valid
  }

  private validateFileSelection(filenames: string[], config: LocalConfig) {

    if(filenames.length != config.fileCount) {

      this.errorToast(`Invalid number of files - expected ${config.fileCount}`)
      return false
    }

    if(this.validateMultipleFileExtensions(filenames, config.fileTypes) == false) {

      this.errorToast(`Invalid file extension - expected ${config.fileTypes}`)
      return false
    }

    if(config.type == "software-package") {

      if(filenames[0] != "CRPackage.ini" ||
        filenames[1] != "CRPackage.tar.gz") {

          this.errorToast(`Invalid filenames - expected CRPackage.ini & CRPackage.tar.gz`)
          return false
        }
    }

    return true
  }

  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error',
      autoCloseDelay: 10000
    });
  }  
}
