<div class="wrapper">
    <ix-content-header width="100%"
        hasBackButton="false"
        headerTitle="User Profile"
        headerSubtitle="User account details">
    </ix-content-header>    

    <div class="grid">
        <ix-layout-grid>
          <ix-row class="content-row" *ngIf="name$ | async as name">
            <ix-col class="content-data" size="3"><ix-typography class="content-label" format="display">User</ix-typography></ix-col>
            <ix-col class="content-data" size="9"><ix-typography format="display">{{name}}</ix-typography></ix-col>
          </ix-row>
          <ix-row class="content-row">
            <ix-col class="content-data" size="3"><ix-typography class="content-label" format="display">Email</ix-typography></ix-col>
            <ix-col class="content-data" size="9"><ix-typography format="display">{{email}}</ix-typography></ix-col>
          </ix-row>
          <ix-row class="content-row">
            <ix-col class="content-data" size="3"><ix-typography class="content-label" format="display">Access level</ix-typography></ix-col>
            <ix-col size="9" class="inner-row">
                <ix-row>
                    <ix-col class="content-data" size="3"><ix-typography format="display">{{group}}</ix-typography></ix-col>
                    <ix-col class="icon-data" size="2">
                        <ix-typography format="display">
                            <ix-icon name='about' size='24' class="icon-class icon"></ix-icon>
                            <ix-tooltip style="margin-top:1%" id="tooltip-1" for=".icon-class">{{description}}</ix-tooltip>
                        </ix-typography>
                    </ix-col>
                </ix-row>
        </ix-col>
          </ix-row>
          <ix-row class="content-row">
            <ix-col class="content-data" size="3"><ix-typography class="content-label" format="display">Account organisation</ix-typography></ix-col>
            <ix-col class="content-data" size="9"><ix-typography format="display">{{organization}}</ix-typography></ix-col>
          </ix-row>
        </ix-layout-grid>
    </div>
</div>