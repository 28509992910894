import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '@siemens/ix-angular';
import { LocalFileSelectorComponent, LocalFileSelection } from 'src/app/components/local-file-selector/local-file-selector.component';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @ViewChild(LocalFileSelectorComponent) fileSelector?: LocalFileSelectorComponent
  public localFileSelection?: LocalFileSelection
  public uploadDisabled = true
  public disableStandard = true

  constructor(
    private readonly permissions: PermissionsService,
    private _apiService: ApiService,
    private readonly toastService: ToastService) {}


  ngOnInit(): void {
    this.toastService.setPosition('top-right');

    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }

  fileSelectionChanged(event: any) {

    this.localFileSelection = event

    this.uploadDisabled = !this.localFileSelection?.stateValid
  }

  async upload() {

    this.uploadDisabled = true
    let version = ''

    const fileData = this.localFileSelection
    if(fileData?.fileType == 'software-package') {

      const iniFileText = await fileData.files[0].text()
      const iniFileData = this.parseINIString(iniFileText)

      if(!iniFileData.CRPackage.Version) {
        throw "No version found in ini file"
      }

      version = `Version ${iniFileData.CRPackage.Version}/`
    }


    fileData?.files.forEach(f => {

      const filename = `${fileData?.fileType}/${fileData?.deviceType}/${version}${f.name}`
      console.log(filename)
      this._apiService.uploadFile(filename, f).subscribe({
        next: () => {

          this.toastService.show({
            message: 'File uploaded',
            type: 'success',
            autoCloseDelay: 10000
          });
        
        },
        error: (e: any) => {
          this.toastService.show({
            message: e.message,
            type: 'error',
            autoCloseDelay: 10000
          })        
        }
      })
    })
  }

  // Well, this is hideous but StackOverflow says it should be fine.
  private parseINIString(data: string){
    var regex = {
        section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
        param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
        comment: /^\s*;.*$/
    };
    var value: any = {};
    var lines: string[] = data.split(/[\r\n]+/);
    var section: string | undefined
    lines.forEach(function(line: string) {
        if(regex.comment.test(line)){
            return;
        }else if(regex.param.test(line)){
            var match = line.match(regex.param);

            if(match) {
              if(section){
                  value[section][match[1]] = match[2];
              }else{
                  value[match[1]] = match[2];
              }
            }
        }else if(regex.section.test(line)){
            var match = line.match(regex.section);

            if(match) {
              value[match[1]] = {};
              section = match[1];
            }
        }else if(line.length == 0 && section){
            section = undefined;
        };
    });
    return value;
  }  
}
