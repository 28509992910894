import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';

@Component({
  selector: 'crmt-device-selector',
  templateUrl: './crmt-device-selector.component.html',
  styleUrls: ['./crmt-device-selector.component.css']
})
export class CrmtDeviceSelectorComponent implements OnInit {
  @Input() deviceType = '';
  @Input() updateCommand = '';
  @Input() allowGroups = true
  public groupMode = true
  @Input() hideOption = false
  public hideOptionStatus = false

  private selectedDevices: any[] = []
  private selectedGroups: string[] = []

  constructor(private nextBtnStateService: NextBtnStateService) { }

  ngOnInit(): void {
    this.groupMode = this.allowGroups
    this.hideOptionStatus = this.hideOption
  }

  public selectionHandler(event: { groups: string[], devices: any[] }) {
    this.selectedGroups = event.groups;
    this.selectedDevices = event.devices;
    this.groupMode = this.selectedDevices.length === 0;
  }

  public hideOptionStatusHandler(): boolean {
    return this.hideOptionStatus
  }
  
  public getData() {
    if(this.groupMode) {

      return {
        done: this.selectedGroups.length > 0 ? true : false,
        groupMode: this.groupMode,
        groups: this.selectedGroups
      }
    }
    else {
      return {
        done: this.selectedDevices.length > 0 ? true : false,
        groupMode: this.groupMode,
        devices: this.selectedDevices
      }
  
    }
  }

}
