import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService, ToastService } from '@siemens/ix-angular';
import { switchMap } from 'rxjs';
import { CrmtCommandSelectorComponent } from 'src/app/components/crmt-command-selector/crmt-command-selector.component';
import { CrmtFileEditorComponent } from 'src/app/components/crmt-file-editor/crmt-file-editor.component';
import { OrientationToolComponent } from 'src/app/components/orientation-tool/orientation-tool.component';
import { ApiService, CommandJobRequest, DeployJobRequest } from 'src/app/services/api.service';
import { EditStateService } from 'src/app/services/edit-state.service';
import { FileHeaderService } from 'src/app/services/file-header.service';
import { OrientationPackageData, OrientationToolService } from 'src/app/services/orientation-tool.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.css']
})
export class DeviceInfoComponent implements OnInit {

  @ViewChild(CrmtCommandSelectorComponent) commandSelector?: CrmtCommandSelectorComponent
  @ViewChild(CrmtFileEditorComponent) fileEditor?: CrmtFileEditorComponent
  @ViewChild(OrientationToolComponent) orientatioinTool?: OrientationToolComponent
  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  public selectedTab = 0;
  public showUpdateDevice = false
  public deviceId: string | null = null
  public deviceStockNumber: string | null = null
  public disableStandard = true
  private orientation?: OrientationPackageData
  public isDeviceConnected: boolean = false
  public jobId!: string
  public loadingUploadFile = false
  public fileTypeChanged = false
  public disableUpdate = true
  public disableUpdateDeviceButton = true
  public selectedCommand = ''
  public selectedCommandChanged = false
  public iconColor:string = '';

  constructor(
    private readonly permissions: PermissionsService,
    private readonly _apiService: ApiService,
    private readonly orientationService: OrientationToolService,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly editStateService: EditStateService,
    private readonly modalService: ModalService,
    private readonly elementRef: ElementRef,
    private fileHeaderService: FileHeaderService
  ) {

    this.deviceId = this.route.snapshot.paramMap.get('deviceId')
    this.deviceStockNumber = this.route.snapshot.paramMap.get('thingStockNumber')
  }

  ngOnInit() {
    this.permissions.getUserLevel().subscribe(level => {

      this.disableStandard = level < UserLevel.STANDARD
    })
  }

  handleInventoryInfo(selection: any) {
    if(selection.attributes?.connected === 'false'){
      this.iconColor='color-alarm--active'
      this.warningToast(`${this.deviceStockNumber} device is disconnected. Hence Config Files, Command and Orientation features will be unavailable.`)
    }else{
      this.isDeviceConnected = true;
      if(selection.hmiActivated==='Unknown' || selection.hmiActivated==='true'){
        this.iconColor='color-warning--active'
      }
      else if(selection.hmiActivated==='false'){
        this.iconColor= 'color-primary--active'
      }
    }
  }

  public changeTab(tabIndex: number) {
    if(this.editStateService.getEditedState()){
      this.openModal(tabIndex)
    }else{
      this.tabsChanged(tabIndex)
    }
  }

  public tabsChanged(tabIndex: number){
    this.selectedTab = tabIndex;
    if (this.selectedTab == 2 ||
      this.selectedTab == 3 ||
      this.selectedTab == 4) {
      this.showUpdateDevice = true
    }
    else {
      this.showUpdateDevice = false
    }
    this.jobId = ''
    this.loadingUploadFile = false
    this.disableUpdate = true
    this.disableUpdateDeviceButton = true
  }

  public getFileType(e: any) {
    if(this.selectedTab === 2 && (e === "fault-log" || e === "fan-usage-log" || e === "service-number-database" || e === "response-action-database" || e === "period-timer-parameters")){
      this.disableUpdateDeviceButton = false
    } else {
      this.disableUpdateDeviceButton = true
    }

    if(e) this.disableUpdate = false
  }

  public getCommandType(e: any) {
    if(e !== this.selectedCommand) {
      this.selectedCommand = e
      this.selectedCommandChanged = true
    }
    if(e) this.disableUpdate = false
  }

  public orientationHandler(orientation: OrientationPackageData) {
    this.orientation = orientation
    if(orientation.offset && orientation.trainClass) this.disableUpdate = false
  }

  public updateDeviceHandler() {

    if (!this.deviceId) return

    if (this.selectedTab == 2) {

      this.updateFile()
    }
    else if (this.selectedTab == 3) {

      this.sendCommand()
    }
    else if(this.selectedTab == 4) {

      this.updateOrientation()
    }
  }
  validatefileNameVersion(fileData: any): boolean {
    const file  = this.fileHeaderService.getFileHeaderValues();
    const header = fileData.header;
    const fileName = header.name? header.name :file.name
    const majorVersion = header.majorVersion? header.majorVersion : file.majorVersion
    const versionStr = file.versionStr;
    if (fileName === '' && (Number.isNaN(majorVersion))) {
      this.errorToast('File name and version can not be empty.')
      return false
    } else if (fileName === '') {
      this.errorToast('File name can not be empty.')
      return false
    } else if (Number.isNaN(majorVersion)) {
      if (versionStr === '') {
        this.errorToast('File version can not be empty.')
      } else {
        this.errorToast('File version is not valid. Enter numbers only')
      }
      return false
    } else if(!fileData.entries.length){
      this.errorToast('Empty file can not be uploaded.');
      return false;
    }
     else {
      return true
    }
  }
  private updateFile() {
    if (!this.deviceId) return
    this.fileTypeChanged = false
    // Fetch the data from file editor component
    const data: any = this.fileEditor?.getData()
    const fileData =  data.fileData;
    //Validate file data 
    const validfileNameVersion = this.validatefileNameVersion(fileData);
    // Send the file to the device
    if (data.done == true && validfileNameVersion) {
      this.loadingUploadFile = true
      this.showToastMessage("Sending update request ...")

      this._apiService.putFile(
        `test/encoded.spb`, // TODO - this needs to be put in a tmp-style folder using a uuid filename
        data?.fileType,
        [this.deviceId],
        data.fileData)
        .subscribe({
          next: (data) => {
            this.loadingUploadFile = false
            this.jobId = data.message.jobId
            this.editStateService.setEditedState(false);
            this.showToastMessage()
            this.fileEditor?.exitEditMode()
          },
          error: (e: any) => {
            this.errorToast(e.message)
            this.loadingUploadFile = false
            this.jobId = ''
          }
        })
    } else if(!data.done){
      this.toastService.setPosition('top-right');
      this.toastService.show({
        message: 'There are no updates in file hence device will not be updated.',
        type: "info",
        autoCloseDelay: 10000
      });
    }
  }

  private sendCommand() {

    if (!this.deviceId) return

    this.selectedCommandChanged = false

    this.showToastMessage("Sending update request ...")

    // Fetch the command data
    const data = this.commandSelector?.getData()

    if (data.done == true) {

      const command = data.type.toLowerCase().replace(' ', '-')

      // Prepare the request
      const job: CommandJobRequest = {
        command,
        groups: [],
        devices: [this.deviceId],
        scheduleLater: false,
        swVersion: data.swVersion,
        stockType: data.stockType,
        stockNumber: data.stockNumber,
        languageCode: data.languageCode
      }

      // Send it to the device
      this._apiService.sendCommand(job).subscribe({
        next: (data) => {
          this.jobId = data.message.jobId
          this.showToastMessage()
        },
        error: (e) => {
          this.errorToast(e.message)
        }
      })
    }
  }

  private updateOrientation() {

    if(!this.deviceId || !this.orientation) return

    this.showToastMessage("Sending update request ...")

    const deviceId = this.deviceId // TypeScript complains without this

    this.orientationService.createPackage(this.orientation).pipe(
      switchMap(data => {

        const job: DeployJobRequest = {
          fileType: "software-package",
          devices: [deviceId],
          scheduleLater: false,
          filename: data.message.packageFolder
        }

        return this._apiService.deploy(job)
      })
    )
      .subscribe({
        next: (data) => {
          console.log(data)
          this.showToastMessage()
        },
        error: (e) => {
          console.error('Error occurred:', e);
          if(e.status == 0){// This is the 0 = unknown Error
            this.errorToast("Unable to process your request. Please try again later or contact the administrator.")
          }else{
            this.errorToast(`Error occurred while deploying job. Please contact administrator.`)
          }
        }
      })
  }

  public onFileTypeChange(e: any){
    this.fileTypeChanged = e;
    if(e) {
      this.disableUpdate = true
      this.jobId = ''
      this.loadingUploadFile = false
    }
  }

  async showToastMessage(message = "Job submitted") {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message,
      type: "success",
      autoCloseDelay: 10000
    });
  }

  async errorToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'error',
      autoCloseDelay: 10000
    });
  }

  async warningToast(message: string) {
    this.toastService.setPosition('top-right');
    this.toastService.show({
      message: message,
      type: 'warning',
      autoCloseDelay: 10000
    });
  }

  async openModal(tabIndex:number) {
    const instance = await this.modalService.open({
      content: this.customModalRef,
      data: '',
    })

    instance.onClose.on(() => {
      this.editStateService.setEditedState(false);
      this.tabsChanged(tabIndex)
    });

    instance.onDismiss.on(() => {
      const tabs = this.elementRef.nativeElement.querySelector('ix-tabs')
      tabs.selected = this.selectedTab
    })

    instance.htmlElement.addEventListener(
      'keydown',
      (keyboardEvent: KeyboardEvent) => {
        console.log(keyboardEvent.key);
      }
    );
  }

  ngOnDestroy() {
    this.editStateService.setEditedState(false);
  }
}
