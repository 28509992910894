import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { OktaAuthStateService } from '@okta/okta-angular';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SuperUserGuard {

  constructor(
    private oktaAuth :OktaAuthStateService,
    private _apiService: ApiService,
    private router: Router)
  {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    const guardResult = this._apiService.getAccountInfo().pipe(
      switchMap(accountInfo => {

        return this.oktaAuth.authState$.pipe(
          switchMap(authState => {
    
            const groups = authState.accessToken?.claims['groups'] as string[]
            const superUser = groups.includes(`${accountInfo.appPrefix}-super`)
            const url = this.router.createUrlTree(["/", "profile"]);

            return of(superUser || url)
        }))
      })
    )

    return guardResult
  }
}
